import React, { useState, useEffect, useRef } from 'react';
import { useCookies } from "react-cookie";
import classNames from 'classnames';
import { Routes, Route, Navigate, useNavigate,Outlet } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import { AppTopbar } from './AppTopbar';
import { AppFooter } from './AppFooter';
import { AppMenu } from './AppMenu';
import { AppProfile } from './AppProfile';
//import { AppConfig } from './AppConfig';

import { Home } from './components/Home';
import { AzureCloud } from './components/AzureCloud';
import { AmazonCloud } from './components/AmazonCloud';
import { Login }  from './components/Login';

import { MarketGrid } from './components/MarketGrid';
import { AgGrid }  from './components/AgGrid';
import { ChangePassword }  from './components/ChangePassword';
/*
import { RegisterForm }  from './components/RegisterForm';
import { RegisterDetails }  from './components/RegisterDetails';
import { RegisterKYC }  from './components/RegisterKYC';
import { RegisterUpload }  from './components/RegisterUpload';
import { AccountDetails }  from './components/AccountDetails';


import { SecuritiesInfo }  from './components/SecuritiesInfo';
import { CreatePortfolio }  from './components/CreatePortfolio';
import { ViewPortfolio }  from './components/ViewPortfolio';

import { CreateSubFund }  from './components/CreateSubFund';
import { ViewSubFund }  from './components/ViewSubFund';
import { FundRequest }  from './components/FundRequest';
*/

import { UserDashboard }  from './components/UserDashboard';


import { EmptyPage } from './pages/EmptyPage';
import  useToken from './components/useToken';
import PrimeReact from 'primereact/api';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';

import './layout/layout.scss';
import './App.scss';


//export const PrivateRoute = ({component: Component, ...rest}) => {
function PrivateRoute() {

    const { userInfo, getToken } = useToken();
    
    return userInfo ? <Outlet />  : <Navigate to="/" replace/>;
};

function AdminRoute() {
    const { userInfo, getToken } = useToken();
    let allow=false;

    if (userInfo) {
        //console.log(userInfo.usertype);
        if (userInfo.usertype === 'A')
        {
            allow=true;
            return (<Outlet />);
        }
    }
    return allow ? <Outlet />  : <Navigate to="/" replace/>;
};

const App = () => {
    const [cookies, setCookie, removeCookie] = useCookies(["user"]);
    const [layoutMode, setLayoutMode] = useState('overlay');
    const [layoutColorMode, setLayoutColorMode] = useState('light')
    //const [inputStyle, setInputStyle] = useState('outlined');
    const [inputStyle, setInputStyle] = useState('filled');
    const [ripple, setRipple] = useState(true);
    const [sidebarActive, setSidebarActive] = useState(false);
    const sidebar = useRef();
    const navigate = useNavigate();
    const { userInfo, getToken } = useToken();
    const { userInfo1, removeToken } = useToken();
    const [isUserActive, setUserState] = useState(CheckLogin());

    let menuClick = false;
    
    useEffect(() => {
        if (sidebarActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [sidebarActive]);

    function CheckLogin() {
        if (userInfo) {
           return (true);
        }
        else {
            return (false);
        }
    }    
    
    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    }

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value)
    }

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode)
    }

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode)
    }
    
    const onWrapperClick = (event) => {
        if (!menuClick && layoutMode === "overlay") {
            setSidebarActive(false);
        }
        menuClick = false;
    }

    const onToggleMenu = (event) => {
        menuClick = true;

        setSidebarActive((prevState) => !prevState);       

        event.preventDefault();
    }

    const onSidebarClick = () => {
        menuClick = true;
    }

    const onMenuItemClick = (event) => {
        if (!event.item.items && layoutMode === "overlay") {
            setSidebarActive(false);
        }
    }

    const menu = [
        { label: 'Home', icon: 'pi pi-fw pi-home', to: '/' },
        { label: 'Amazon Cloud', icon: 'pi pi-fw pi-home', to: '/AmazonCloud' },
        { label: 'Azure Cloud', icon: 'pi pi-fw pi-home', to: '/AzureCloud' },
        { label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/UserDashboard' },
        {
            label: 'Table Grid', icon: 'pi pi-fw pi-sitemap',
            items: [
                { label: 'Market Grid', icon: 'pi pi-fw pi-table', to: '/marketgrid' },
                { label: 'Ag Grid', icon: 'pi pi-fw pi-table', to: '/aggrid' },
                { label: 'Empty Page', icon: 'pi pi-fw pi-circle-off', to: '/empty' }
            ]
        }
    ];

    const adminmenu = [
        { label: 'Home', icon: 'pi pi-fw pi-home', to: '/' },
        { label: 'Amazon Cloud', icon: 'pi pi-fw pi-home', to: '/AmazonCloud' },
        { label: 'Azure Cloud', icon: 'pi pi-fw pi-home', to: '/AzureCloud' },
        
        {
            label: 'Table Grid', icon: 'pi pi-fw pi-sitemap',
            items: [                
                { label: 'Market Grid', icon: 'pi pi-fw pi-table', to: '/marketgrid' },
                { label: 'Ag Grid', icon: 'pi pi-fw pi-table', to: '/aggrid' },
                { label: 'Empty Page', icon: 'pi pi-fw pi-circle-off', to: '/empty' }
            ]
        },
        {
            label: 'Admin', icon: 'pi pi-fw pi-sitemap',
            items: [             
                
            ]
        }
    ];

    const addClass = (element, className) => {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    const removeClass = (element, className) => {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    const isSidebarVisible = () => {
        return sidebarActive;
    };

    const logo = layoutColorMode === 'dark' ? '/cloudstats_white.png' : '/cloudstats_blue.png';

    const wrapperClass = classNames('layout-wrapper', {
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-active': sidebarActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': ripple === false
    });

    const sidebarClassName = classNames('layout-sidebar', {
        'layout-sidebar-dark': layoutColorMode === 'dark',
        'layout-sidebar-light': layoutColorMode === 'light'
    });

    function LoginRequest() {
        return <Login userState={isUserActive} onAuthenticate={(type) => setUserState(type)}/>
    }

    function Logout() {
        //console.log(cookies.user);
        
        setCookie("cloudstats_user", "", { path: "/" });
        removeCookie('cloudstats_user');
        

        //removeToken();
        
        //sessionStorage.setItem('user',"");
        setUserState(false);
        //return  <Redirect  to="/" />            
        navigate("/");
    }
    const CheckAdminMenu = () => {
        const { userInfo, getToken } = useToken();
        if (userInfo) {
            //console.log(userInfo.usertype);
            if (userInfo.usertype === 'A')
            {
                return ( <AppMenu model={adminmenu} onMenuItemClick={onMenuItemClick} /> );
            }
        }
        return ( <AppMenu model={menu} onMenuItemClick={onMenuItemClick} /> );
    }

    return (              
        <div className={wrapperClass} onClick={onWrapperClick}>            
            <AppTopbar onToggleMenu={onToggleMenu} userState={isUserActive}/>

            <CSSTransition classNames="layout-sidebar" timeout={{ enter: 200, exit: 200 }} in={isSidebarVisible()} unmountOnExit>
                <div ref={sidebar} className={sidebarClassName} onClick={onSidebarClick}>
                    <div className="layout-logo" style={{cursor: 'pointer'}} onClick={() => navigate('/')}>
                        <img alt="Logo" src={logo} />
                    </div>
                    <AppProfile userState={isUserActive}/>                   
                    {/* <AppMenu model={menu} onMenuItemClick={onMenuItemClick} /> */}
                    <CheckAdminMenu />
                </div>
            </CSSTransition>
           
            
            <div className="layout-main">                
                <Routes>                                        
                    <Route path="/" exact element={<Home/>} />
                    <Route path="/AmazonCloud" exact element={<AmazonCloud/>} />
                    <Route path="/AzureCloud" exact element={<AzureCloud/>} />
                    <Route path="/marketgrid" element={<MarketGrid/>} />
                    <Route path="/aggrid" compelementonent={<AgGrid/>} />
                    <Route path="/empty" element={<EmptyPage/>} />
                    <Route path="/Login" element={<LoginRequest/>} />                
                    <Route path="/Logout" element={<Logout/>} />
                    
                </Routes>               
            </div>
            <AppFooter />

        </div>
    );

}

export default App;
