import React, { useState, useEffect } from 'react';
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import {Link} from 'react-router-dom'
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { Chart } from 'primereact/chart';
import { ProgressBar } from 'primereact/progressbar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';


export const Home = () => {
    {/*
    const [cloudUsage, setcloudUsage] = useState(null);
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});   
    
    useEffect(() => {
        fetch('/cloud/get_cloud_last_month_usage.php')
        .then(result => result.json())
        .then(rowData => setCloudUsageManual(rowData))
    }, []);
    
    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
        
        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                tooltips: {
                    mode: 'index',
                    intersect: false
                },
                legend: {
                    labels: {
                        color: textColor
                    }
                }
            },
            scales: {
                x: {
                    stacked: true,
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                },
                y: {
                    stacked: true,
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                }
            }
        };
        
        fetch('/cloud/get_cloud_service_month_usage.php')
        .then(result => result.json())
        .then(rowData => setChartData(rowData));

        //setChartData(data);
        setChartOptions(options);
    }, []);
    
    
    const setCloudUsageManual = (value) => {
        setcloudUsage(value);   
        
        let CurrMonth = document.getElementById("CurrMonth");
        CurrMonth.textContent = value[0].start_date;
        let CurrMonthUsageId = document.getElementById("CurrMonthUsage");
        let CurrMonthUsage = parseFloat(value[0].total);
        CurrMonthUsageId.textContent = formatCurrency(CurrMonthUsage,"USD");
        
        let PervMonth = document.getElementById("PervMonth");
        PervMonth.textContent = value[1].start_date;
        let PervMonthUsageId = document.getElementById("PervMonthUsage");
        let PervMonthUsage = parseFloat(value[1].total);
        PervMonthUsageId.textContent = formatCurrency(PervMonthUsage,"USD");
        
        let DiffUsageId = document.getElementById("DiffUsage");
        DiffUsageId.textContent = formatCurrency(CurrMonthUsage - PervMonthUsage,"USD");
        
    };
       
    
    const formatCurrency = (value,curr_type) => {              
        return value.toLocaleString('en-US', { minimumFractionDigits: 4, style:'currency', currency:curr_type });
    };

    const numberTemplate = (data, props) => {
        return (
            <>                
                <div style={{ textAlign: 'right' }}>{formatCurrency(parseFloat(data[props.field]),data.currency)}</div>
            </>
        );
    };
    */}

    return (        
        <div className="p-grid p-center dashboard">            
            <div className="p-col-12 p-lg-4">
                <div className="card summary">
                    <Link to="/AmazonCloud">
                        <img alt="Amazon" src='/amazon.png' />                        
                    </Link>                    
                </div>
            </div>
            <div className="p-col-12 p-lg-4">
                <div className="card summary">
                    <Link to="/AzureCloud">
                        <img alt="Azure" src='/azure.png' />                        
                    </Link>                    
                </div>
            </div>            
        </div>
    );
}
